<template>
  <div class="wap-open-shop">
    <headerBar :title="$t('shang-jia-ru-zhu')" @back="back"></headerBar>

    <div class="top">
      <div class="flex-center-between header">
        <div class="logo flex-center-start" @click="toPath('index')">
          <img :src="logo || logoUrl" alt="" />
          <span class="app-name">{{ appName }}</span>
        </div>
        <div class="flex-center lang-box">
          <el-dropdown trigger="click">
            <div class="lang-info flex-center">
              <img :src="langIcon" alt="" />
              <i
                class="el-icon-caret-bottom"
                style="color: #fff; font-size: 14px"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown" class="lang-dropdown">
              <el-dropdown-item v-for="(item, i) in langList" :key="i">
                <div
                  class="flex-center-start lang-menu"
                  @click="changeLang(item)"
                >
                  <img :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="title">{{ $t('appname-shang-jia-ru-zhu', [appName]) }}</div>
      <div class="sub-title">
        {{
          $t(
            'wo-men-de-he-zuo-huo-ban-ji-hua-wei-nin-ti-gong-quan-fang-wei-de-ying-xiao-zhi-chi-he-fu-wu-wo-men-de-ke-hu-fu-wu-tuan-dui-jiang-ti-gong-zhuan-ye-de-zhi-chi-he-jian-yi-bang-zhu-nin-you-hua-nin-de-chan-pin-zhan-shi-he-xiao-shou-ce-lve'
          )
        }}
      </div>
      <div class="sub-title">
        {{
          $t(
            'xian-zai-jiu-jia-ru-wo-men-ba-rang-wo-men-yi-qi-chuang-zao-geng-da-de-shang-ye-ji-hui-gong-tong-cheng-chang'
          )
        }}
      </div>
    </div>

    <div class="main-form">
      <div class="title">{{ $t('shang-ye-xin-xi') }}</div>
      <div class="info">
        {{ $t('ru-guo-nin-yi-shi-mai-jia-qing') }}
        <span class="blue" @click="toPath('login')">{{
          $t('dian-ji-deng-lu')
        }}</span>
      </div>

      <el-form
        :model="form"
        ref="form"
        class="register-form"
        label-position="left"
        :rules="formRule"
      >
        <el-form-item :label="$t('dian-pu-biao-zhi')" prop="Logo">
          <el-upload
            class="avatar-uploader bottom"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="(file) => uploadSuccess(file, 'Logo')"
            :before-upload="beforeUpload"
          >
            <img v-if="form.Logo" :src="getBaseUrl(form.Logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('dian-pu-ming-cheng')" prop="ShopName">
          <Field
            v-model="form.ShopName"
            :placeholder="$t('qing-shu-ru-dian-pu-ming-cheng')"
          ></Field>
        </el-form-item>
        <el-form-item :label="$t('dian-pu-di-zhi')" prop="ShopAddress">
          <Field
            v-model="form.ShopAddress"
            :placeholder="$t('qing-shu-ru-dian-pu-di-zhi')"
          ></Field>
        </el-form-item>

        <el-form-item :label="$t('zheng-jian-hu-zhao-hao-ma')" prop="IdCard">
          <Field
            v-model="form.IdCard"
            :placeholder="$t('qing-shu-ru-zheng-jian-hu-zhao-hao-ma')"
          ></Field>
        </el-form-item>
        <el-form-item :label="$t('zhen-shi-xing-ming')" prop="RealName">
          <Field
            v-model="form.RealName"
            :placeholder="$t('qing-shu-ru-zhen-shi-xing-ming')"
          ></Field>
        </el-form-item>
        <el-form-item
          :label="$t('zheng-jian-zhao-shang-chuan-hu-zhao')"
          required
          prop="IdCardFront"
        >
          <div class="flex-center-start upload-line">
            <div class="upload-box flex-column-center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                name="file"
                :headers="headers"
                :on-success="(file) => uploadSuccess(file, 'IdCardFront')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="form.IdCardFront"
                  :src="getBaseUrl(form.IdCardFront)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
              <span class="text">{{ $t('zheng-jian-zheng-mian') }}</span>
            </div>
            <div class="upload-box flex-column-center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                name="file"
                :headers="headers"
                :on-success="(file) => uploadSuccess(file, 'IdCardBack')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="form.IdCardBack"
                  :src="getBaseUrl(form.IdCardBack)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
              <span class="text">{{ $t('zheng-jian-fan-mian') }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('pai-she-shi-li')" prop="demo">
          <div class="flex-center-start demo-line">
            <img :src="demoUrl1" alt="" />
            <img :src="demoUrl2" alt="" />
          </div>
        </el-form-item>

        <el-form-item :label="$t('dian-pu-jie-shao')" prop="ShopIntro">
          <Field
            type="textarea"
            v-model="form.ShopIntro"
            :placeholder="$t('qing-shu-ru-dian-pu-jie-shao')"
          ></Field>
        </el-form-item>
      </el-form>
      <div class="action-btn flex-column-center">
        <Checkbox v-model="checked" checked-color="#9d48f7" icon-size="16px"
          >{{ $t('wo-yi-jing-yue-du-bing-tong-yi')
          }}<span>{{ $t('ru-zhu-xie-yi') }}</span></Checkbox
        >
        <Button class="btn" type="primary" @click="submit">{{
          $t('ti-jiao-shen-qing-biao')
        }}</Button>
      </div>
    </div>

    <footerBar></footerBar>
    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, Popup, Checkbox, Field } from 'vant'
import headerBar from '@/components/header'
import CountryList from '@/components/countryList'
import { getToken } from '@/utils/auth'
import { openShop } from '@/api/shop'
import footerBar from '@/components/footer/wapFooter'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    CountryList,
    Button,
    headerBar,
    Checkbox,
    Popup,
    Field,
    footerBar
  },
  data() {
    return {
      checked: false,
      avatar: '',
      form: {
        phone: '',
        phonePre: '+86'
      },
      headers: {},
      showDialog: false,
      showPassword: false,
      logoUrl: require('@/assets/imgs/logo.png'),
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png'),
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      formRule: {
        RealName: [
          {
            required: true,
            message: this.$t('qing-shu-ru-zhen-shi-xing-ming-0')
          }
        ],
        Phone: [
          { required: true, message: this.$t('qing-shu-ru-shou-ji-hao-1') }
        ],
        IdCard: [
          {
            required: true,
            message: this.$t('qing-shu-ru-zheng-jian-hu-zhao-hao-ma-0')
          }
        ],
        Email: [
          { required: true, message: this.$t('qing-shu-ru-you-xiang-0') }
        ],
        Logo: [{ required: true, message: this.$t('qing-shang-chuan-logo') }],
        IdCardFront: [
          {
            required: true,
            validator: this.checkIdCardFront
          }
        ],
        ShopName: [
          {
            required: true,
            message: this.$t('qing-shu-ru-dian-pu-ming-cheng-0')
          }
        ],
        ShopIntro: [
          { required: true, message: this.$t('qing-shu-ru-dian-pu-jie-shao-0') }
        ],
        ShopCountry: [
          { required: true, message: this.$t('qing-xuan-ze-guo-jia') }
        ],
        ShopProvince: [
          { required: true, message: this.$t('qing-shu-ru-sheng-fen') }
        ],
        ShopCity: [
          { required: true, message: this.$t('qing-shu-ru-cheng-shi-0') }
        ],
        ShopAddress: [
          { required: true, message: this.$t('qing-shu-ru-di-zhi') }
        ]
      }
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    countryList() {
      return this.$store.state.countryList
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      this.$router.push(path)
    },
    checkIdCardFront(rule, value, callback) {
      if (!value) {
        return callback(this.$t('qing-shang-chuan-zheng-jian-zheng-mian'))
      } else if (!this.form.IdCardBack) {
        callback(this.$t('qing-shang-chuan-zheng-jian-fan-mian'))
      } else {
        callback()
      }
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          openShop({
            IndustryID: 1,
            CategoryID: '1',
            HandIdCard: '',
            Logo: this.form.Logo,
            RealName: this.form.RealName,
            Phone: this.form.Phone,
            IdCard: this.form.IdCard,
            Email: this.form.Email,
            IdCardFront: this.form.IdCardFront,
            IdCardBack: this.form.IdCardBack,
            ShopName: this.form.ShopName,
            ShopIntro: this.form.ShopIntro,
            ShopCountry: this.form.ShopCountry,
            ShopProvince: this.form.ShopProvince,
            ShopCity: this.form.ShopCity,
            ShopAddress: this.form.ShopAddress
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('shen-qing-cheng-gong'))
              this.$refs.form.resetField()
              this.$router.push({
                name: 'index'
              })
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    uploadSuccess(res, type) {
      this.form[type] = res.data.FileName
      this.$forceUpdate()
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    changeCountry() {
      this.showDialog = true
    }
  }
}
</script>